<template>
  <footer class="footer has-background-dark">
    <div class="columns footer-wrapper">
      <div
        class="column is-4-tablet is-12-mobile footer-column has-divider-right"
      >
        <p class="footer-paragraph">Techniker ZT Sp. z o.o. Sp.k.</p>
        <p class="footer-paragraph">NIP: 7822877483</p>
        <p class="footer-paragraph">REGON: 385290123</p>
        <p class="footer-paragraph">KRS: 0000821637</p>
      </div>
      <div
        class="column is-4-tablet is-12-mobile footer-column has-divider-right"
      >
        <p class="footer-paragraph">Główna 61,</p>
        <p class="footer-paragraph">61-019 Poznań</p>
        <p class="footer-paragraph">61 878 08 24</p>
        <p class="footer-paragraph">kontakt@techniker.pl</p>
      </div>
      <div class="column is-4-tablet footer-column is-12-mobile">
        <p class="footer-paragraph">© 2021 Techniker ZT</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style scoped lang="scss">
.footer {
  padding: 3rem;
  width: 100%;
  height: auto;
  bottom: 0;
}
.footer-wrapper {
  width: 66.666%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.footer-paragraph {
  color: $grey-light;
  font-size: 14px;
}

.footer-column {
  justify-content: center;
}

@media only screen and (min-width: 769px) {
  .has-divider-right {
    border-right: 1px solid $grey-light;
    min-height: 40px;
  }

  .footer-wrapper {
    flex-direction: row;
  }
}
</style>
